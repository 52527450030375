
    export default {
        name: 'AnimatedPerson',

        mounted() {
            this.animatedPerson();
        },

        methods: {
            animatedPerson() {
                this.$velocity(this.$refs.person, { translateY: '16px' }, { duration: 1800, easing: 'easeInOut', loop: true });
            },
        },
    };


    import AnimateRing from '@/components/Utils/Animates/AnimateRing';

    export default {
        name: 'AnimatedMatchScoreCard',

        components: {
            AnimateRing,
        },
    };

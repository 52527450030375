
    export default {
        name: 'AnimatedStar',

        props: {
            delay: {
                type: Number,
                default: 2000,
            },
            size: {
                type: Number,
                default: 10,
            },
        },

        mounted() {
            if (!this.disableAnimation) {
                this.animatedStar();
            }
        },

        beforeDestroy() {
            this.$velocity(this.$refs.star, 'stop', true);
        },

        methods: {
            animatedStar() {
                this.$velocity(
                    this.$refs.star, 'fadeIn',
                    {
                        duration: 300,
                        easing: 'linear',
                        begin: () => {
                            this.$velocity(this.$refs.star, { scale: 5 }, { duration: 300, easing: 'linear', queue: false });
                        },
                        complete: (elem) => {
                            this.$velocity(elem, 'fadeOut', { duration: 50, delay: this.delay });
                            this.$velocity(elem, { scale: 1 }, { duration: 50, easing: 'linear', delay: this.delay, queue: false });
                            this.animatedStar();
                        },
                    },
                );
            },
        },
    };

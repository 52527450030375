
    import AnimatedMatchScoreCard from '@/components/Utils/Animates/AnimatedMatchScoreCard';
    import AnimatedPerson from '@/components/Utils/Animates/AnimatedPerson';
    import AnimatedStar from '@/components/Utils/Animates/AnimatedStar';

    export default {
        name: 'C1Splash',

        components: {
            AnimatedStar,
            AnimatedMatchScoreCard,
            AnimatedPerson,
        },
    };
